import './css/App.css';
import profile_img from './img/profile.jpg';

import Spinner from './Spinner';

import React, {Component} from 'react';

function parse_time(date_str) {
  const date = new Date(date_str)
  return date.toLocaleTimeString("en-US", {
    timeZone: "Europe/London",
    hour: "numeric",
    minute:"2-digit",
    hour12: true,
  }).replace(" ", "").toLowerCase();
}

function parse_range(date_start_str, date_end_str) {
  var date_start = parse_time(date_start_str);
  const date_end = parse_time(date_end_str);
  if(date_start.slice(-2) === date_end.slice(-2)) {
    date_start = date_start.substring(0, date_start.length - 2);
  }
  return date_start+"-"+date_end
}

class App extends Component {
  state = {
    times: {},
    status: "loading",
    last_update: "",
    full: false
  }

  componentDidMount() {
    fetch("https://europe-west2-mrkt-analytics.cloudfunctions.net/get-cal-times?ts="+Date.now())
    .then(res => res.json())
    .then((data) => {
      this.setState({ status: "ok", times: data.data , last_update: data.updated })
    })
    .catch((e) => {
      this.setState({ status: "error" })
    })
  }

  render () {
    const time_zone = new Date().toLocaleTimeString("en-GB",
      {timeZone: "Europe/London", timeZoneName:"short"}).split(" ").pop();
    return (
      <div className="App">
        <div className="availability">
          <h1>
            <a href="https://www.maschaefer.net/" target="_blank" rel="noreferrer">
              <img src={profile_img} alt="Profile" className="profile-img" />
            </a>
            Availability
            <small className="text-muted"> | TZ: London / {time_zone}</small>
          </h1>
          <hr className="header-hr" />
          {this.state.status === "error" &&
              <div className="error-box">
                Error: There are no available time slots
              </div>
          }
          {this.state.status === "loading" &&
            <div className="loading-box">
              <Spinner />
            </div>
          }
          {this.state.status === "ok" &&
            Object.entries(this.state.times).map(([day, values]) => {
              var day_date = new Date(day);
              const time_string = values.times.map((t, i) => { 
                return(
                  <span key={i}>
                    {parse_range(t.start, t.end)}
                    {(i+1) < values.times.length ? ", " : ""}
                  </span>
                );
              });
              const day_name = day_date.toLocaleDateString("en-GB", { weekday: "long" });
              const month_name = day_date.toLocaleDateString("en-GB", { month: "short"});
              const day_number = day_date.toLocaleDateString("en-GB", {  day: "numeric"});
              return (
                <div className={values.id < 5 | this.state.full ? "time-box" : "time-box hidden"} key={values.id}>
                  <p className="day-date">{day_name} <span className="date">{month_name} {day_number}</span></p>
                  <p className="times">{time_string}</p>
                </div>
              )
            })
          }
          {this.state.status === "ok" &&
            <div className="center">
              {Object.keys(this.state.times).length > 5 &&
              <button className="more-button" onClick={() => { this.setState({ full: !this.state.full })}}>
                show { this.state.full ? "less" : "more"}
              </button>
              }
            </div>
          }
          <hr className="updated-hr" />
          <div className="center updated-text">
            <small>Last Update: {this.state.last_update}</small>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
